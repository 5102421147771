import React from 'react'
import Testimonials from '../components/Testimonials';
import Features from '../components/Features';
import SearchDomain from '../components/SearchDomain';
import Endorsment from '../components/Endorsment';

const Home = () => {
  return (
    <div>
    <Features/>
    <Testimonials/>
    <Endorsment/></div>
  )
}

export default Home;